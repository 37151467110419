import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './Company.css'; // Import the CSS file
import TotalData from "../../JsonFiles/TotalData.json";
import services from "../../JsonFiles/Services.json";

// Lazy load components
const AllIntroTemplate = lazy(() => import('../../components/AllIntroTemplate/AllIntroTemplate'));
const AllAboutTemplate = lazy(() => import('../../components/AllAboutTemplate/AllAboutTemplate'));
const AllHighlights = lazy(() => import('../../components/AllHighlights/AllHighlights'));
const AllSustainability = lazy(() => import('../../components/AllSustainability/AllSustainability'));
const AllValues = lazy(() => import('../../components/AllValues/AllValues'));
const CompanyQuotes = lazy(() => import('../../components/CompanyQuotes/CompanyQuotes'));
const PhotoSlider= lazy(() => import('../../components/CompanyTimeline/CompanyTimelineGrid'));
const CompanyDirectors = lazy(() => import('../../components/CompanyDirectors/CompanyDirectors'));
const HomeServicesCatelogs = lazy(() => import('../../components/HomeServicesCatelogs/HomeServicesCatelogs'));
const AllMapOffice = lazy(() => import('../../components/AllMapOffice/AllMapOffice'));
const CompanyPdfViewer = lazy(() => import('../../components/CompanyPdfViewer/CompanyPdfViewer'));

function Company() {
  const companyData = TotalData[0];

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Priya Infra Engineers Pvt Ltd",
    "url": "https://www.priyainfra.com/company",
    "logo": companyData.Companyimage,
    "description": companyData.CompanyDescription,
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Raichur",
      "addressRegion": "Karnataka",
      "addressCountry": "India"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "Customer Service",
      "telephone": "+91 9594117963",
      "email": companyData.Email
    },
    "sameAs": [
      "https://www.facebook.com/priyainfra",
      "https://www.linkedin.com/company/priyainfra"
    ]
  };
  

  return (
    <div className="Company-container">
     <Helmet>
  <title>Company - Priya Infra Engineers Pvt Ltd | Leading Construction & Engineering Services</title>
  <meta name="description" content={companyData.CompanyDescription} />
  <meta property="og:title" content="Company - Priya Infra Engineers Pvt Ltd" />
  <meta property="og:description" content={companyData.CompanyDescription} />
  <meta property="og:image" content={companyData.Companyimage} />
  <meta property="og:url" content="https://www.priyainfra.com/company" />
  <meta property="og:type" content="website" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Company - Priya Infra Engineers Pvt Ltd" />
  <meta name="twitter:description" content={companyData.CompanyDescription} />
  <meta name="twitter:image" content={companyData.Companyimage} />
  <script type="application/ld+json">
    {JSON.stringify(structuredData)}
  </script>
</Helmet>

      <Suspense fallback={<div>Loading...</div>}>
        <AllIntroTemplate 
          description={companyData.CompanyDescription} 
          image={companyData.Companyimage} 
          title={companyData.Companytitle} 
        />
        <AllAboutTemplate 
          description={companyData.CompanyAboutPara} 
          image={companyData.CompanyAboutimage} 
          title={companyData.CompanyAboutTitle} 
          image2={companyData.CompanyAboutimage2} 
        />
        <HomeServicesCatelogs data={services} details={TotalData[0]} />
        <AllHighlights data={TotalData.map(data=>(data.highlights))}/>
        {/* <CompanyTimeline cards={companyData.Timeline} /> */}
        <PhotoSlider cards={companyData.Timeline}/>
        <CompanyPdfViewer data={TotalData[0].PdfCompany}/>
        <AllSustainability data={TotalData} />
        <CompanyQuotes data={companyData} />
        <AllMapOffice data={TotalData[0].MapCity} title={TotalData[0].HeadingMap}/>
        <AllValues />
        <CompanyDirectors cards={TotalData} />
        
      </Suspense>
    </div>
  );
}

export default Company;
