import React from 'react';
import { Helmet } from 'react-helmet';
import './WhyPriya.css'; // Import the CSS file
import AllAboutTemplate from '../../components/AllAboutTemplate/AllAboutTemplate';
import AllIntroTemplate from '../../components/AllIntroTemplate/AllIntroTemplate';
import WhyScrollEffect from '../../components/WhyScrollEffect/WhyScrollEffect';
import AllValues from '../../components/AllValues/AllValues';
import AllSustainability from '../../components/AllSustainability/AllSustainability';
import AllProjects from '../../components/AllProjects/AllProjects';
import AllContactUs from '../../components/AllContactUs/AllContactUs';
import projects from "../../JsonFiles/Projects.json";
import TotalData from "../../JsonFiles/TotalData.json";
import CompanyPdfViewer from '../../components/CompanyPdfViewer/CompanyPdfViewer';

function WhyPriya() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Why Priya - Priya Infra",
    "url": "https://www.priyainfra.com/why-priya",
    "description": "Discover why choosing Priya Infra is the right decision for your projects. Our expertise and commitment to quality set us apart.",
    "publisher": {
      "@type": "Organization",
      "name": "Priya Infra",
      "url": "https://www.priyainfra.com"
    }
  };

  return (
    <div className="about-container">
      <Helmet>
        <title>Why Priya - Priya Infra</title>
        <meta name="description" content="Discover why choosing Priya Infra is the right decision for your projects. Our expertise and commitment to quality set us apart." />
        <meta property="og:title" content="Why Priya - Priya Infra" />
        <meta property="og:description" content="Discover why choosing Priya Infra is the right decision for your projects. Our expertise and commitment to quality set us apart." />
        <meta property="og:url" content="https://www.priyainfra.com/why-priya" />
        <meta property="og:image" content="URL_to_representative_image" /> {/* Replace with actual image URL */}
        <meta property="og:type" content="website" />
        <meta name="keywords" content="Priya Infra, construction services, infrastructure development, civil engineering" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.priyainfra.com/why-priya" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <AllIntroTemplate
        description={TotalData[0].WhyUSDescription}
        image={TotalData[0].WhyUSimage}
        title={TotalData[0].WhyUStitle}
      />
      <AllAboutTemplate
        description={TotalData[0].WhyUSAboutPara}
        image={TotalData[0].WhyUSAboutimage}
        title={TotalData[0].WhyUSAboutTitle}
        image2={TotalData[0].WhyUSAboutimage2}
      />
      <WhyScrollEffect data={TotalData} />
      <CompanyPdfViewer data={TotalData[0].PdfCompany}/>
      <AllValues />
      <AllSustainability data={TotalData} />
      <AllProjects cards={projects} />
      <AllContactUs data={TotalData} />
    </div>
  );
}

export default WhyPriya;
