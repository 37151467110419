import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import AreaOfActivities from './pages/AreaOfActivities/AreaOfActivities';
import Career from './pages/Career/Career';
import Company from './pages/Company/Company'; 
import Projects from './pages/Projects/Projects'; 
import WhyPriya from './pages/WhyPriya/WhyPriya';
import ProjectPage from './pages/Project_page/Project_page'; 
import ServicesPage from './pages/Services_page/Services_page'; 
import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import Sustainability from './pages/Sustainability/Sustainability';
import Contact from './pages/Contact/Contact';
import NotFound from './components/NotFound/NotFound';

const AppRoutes = () => {
  return (
    <Router>
      <div>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<AreaOfActivities />} />
          <Route path="/career" element={<Career />} />
          <Route path="/company" element={<Company />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/why-priya" element={<WhyPriya />} />
          <Route path="/projects/:id" element={<ProjectPage />} /> 
          <Route path="/services/:id" element={<ServicesPage />} />
          <Route path='/sustainability' element={<Sustainability/>} />
          <Route path='*' element={<NotFound />} /> {/* Catch-all route */}
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
};

export default AppRoutes;