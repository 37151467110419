import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import NavLinks from "./NavLinks";
import LazyLoad from 'react-lazyload';
// import "./NavBars.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';




const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const NavBar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const handleLinkClick = () => {
    setOpen(false);
  };

  // console.log(isScrolled);


  useEffect(() => {
    if (location.pathname === "/") {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  }, [location]);


useEffect(() => {
  const checkScroll = () => {
    if (location.pathname === "/") {
      setIsScrolled(window.scrollY > 100);
      
    } else {
      setIsScrolled(true);
    }
  };

  // console.log(location.pathname);

  window.addEventListener('scroll', checkScroll);

  return () => {
    window.removeEventListener('scroll', checkScroll);
  };
}, [location]);

  return (
    <nav id="animated-navbar" className={`sticky top-0 z-50 transition-colors duration-500 ${isScrolled ? 'shadow-lg sm:shadow-xl md:shadow-2xl lg:shadow-3xl xl:shadow-4xl bg-white' : 'bg-transparent'}`}>
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 p-2 lg:w-auto w-full flex justify-between">
          <Link to="/">
            <LazyLoad height={200} offset={100} once>
              <img
                src="/images/logo.webp"
                alt="logo"
                className="w-32 lg:w-32 xl:w-44"
              />
            </LazyLoad>
          </Link>
          <div className={`text-5xl lg:hidden`} onClick={() => setOpen(!open)}>
          <FontAwesomeIcon icon={open ? faTimes : faBars} color={open ? undefined : (isScrolled ? 'black' : 'white')} />
          </div>
        </div>
        <ul className={`lg:flex hidden uppercase items-center gap-12 font-[Poppins] ${isScrolled ? 'text-black' : 'text-white'}`}>
          <li>
            <Link to="/" className="py-7 px-2 inline-block hover:text-blue-500">
              Home
            </Link>
          </li>
          <li>
            <Link to="/why-priya" className="py-7 px-2 inline-block hover:text-blue-500">
              Why US?
            </Link>
          </li>
          <NavLinks isScrolled={isScrolled}/>
          <li>
            <Link to="/company" className="py-7 px-2 inline-block hover:text-blue-500">
              Company
            </Link>
          </li>
          <li>
            <Link to="/sustainability" className="py-7 px-2 inline-block hover:text-blue-500">
              Sustainability
            </Link>
          </li>
          <li>
            <Link to="/career" className="py-7 px-2 inline-block hover:text-blue-500">
              Career
            </Link>
          </li>
        </ul>
        <div className="lg:block hidden"></div>

        {/* mobile navbar */}
        <ul
          className={`
            lg:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
            duration-500 ${open ? "left-0" : "left-[-100%]"} 
          `}
        >
          <li>
            <Link to="/" className="py-7 px-2 inline-block hover:text-blue-500" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/why-priya" className="py-7 px-2 inline-block hover:text-blue-500" onClick={handleLinkClick}>
              Why US?
            </Link>
          </li>
          <li>
            <Link to="/company" className="py-7 px-2 inline-block hover:text-blue-500" onClick={handleLinkClick}>
              Company
            </Link>
          </li>
          <NavLinks handleLinkClick={handleLinkClick} />
          <li>
            <Link to="/sustainability" className="py-7 px-2 inline-block hover:text-blue-500" onClick={handleLinkClick}>
              Sustainability
            </Link>
          </li>
          <li>
            <Link to="/contact" className="py-7 px-2 inline-block hover:text-blue-500" onClick={handleLinkClick}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/career" className="py-7 px-2 inline-block hover:text-blue-500" onClick={handleLinkClick}>
              Career
            </Link>
          </li>
          <li>
            <div className="py-5"></div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
