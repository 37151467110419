import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './Project_page.css'; 
import projects from "../../JsonFiles/Projects.json";
import TotalData from "../../JsonFiles/TotalData.json";
import { useParams } from 'react-router-dom';

// Lazy load components
const AllIntroTemplate = lazy(() => import('../../components/AllIntroTemplate/AllIntroTemplate'));
const AllAboutTemplate = lazy(() => import('../../components/AllAboutTemplate/AllAboutTemplate'));
const OfficeImages = lazy(() => import('../../components/OfficeImages/OfficeImages'));
const AllProjects = lazy(() => import('../../components/AllProjects/AllProjects'));
const AllContactUs = lazy(() => import('../../components/AllContactUs/AllContactUs'));

function Project_page() {
  const { id } = useParams();
  const page = projects.find(page => page.id === parseInt(id));

  if (!page) {
    return <div>Page not found</div>;
  }

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": `Project - ${page.title}`,
    "url": `https://www.priyainfra.com/projects/${page.id}`,
    "description": page.ProjectsDescription,
    "image": page.Projectsimage,
    "publisher": {
      "@type": "Organization",
      "name": "Priya Infra",
      "url": "https://www.priyainfra.com/"
    }
  };

  return (
    <div className="ProjectsPage-container">
      <Helmet>
        <title>{page.title} - Priya Infra</title>
        <meta name="description" content={page.ProjectsDescription} />
        <meta name="keywords" content={`Priya Infra, ${page.title}, construction, project`} />
        <meta name="author" content="Priya Infra Engineers Pvt Ltd" />
        <meta property="og:title" content={`${page.title} - Priya Infra`} />
        <meta property="og:description" content={page.ProjectsDescription} />
        <meta property="og:image" content={page.Projectsimage} />
        <meta property="og:url" content={`https://www.priyainfra.com/projects/${page.id}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://www.priyainfra.com/projects/${page.id}`} />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <AllIntroTemplate 
          description={page.ProjectsDescription} 
          image={page.image} 
          title={page.title} 
        />
        <AllAboutTemplate 
          description={page.ProjectsAboutPara} 
          image={page.ProjectsAboutimage} 
          title={page.ProjectsAboutTitle} 
          image2={page.ProjectsAboutimage2}
        />
        <OfficeImages image={page.photo} />
        <AllProjects cards={projects} />
        <AllContactUs data={TotalData} />
      </Suspense>
    </div>
  );
}

export default Project_page;
