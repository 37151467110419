import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './Sustainability.css';
import TotalData from "../../JsonFiles/TotalData.json";

// Lazy load components
const AllIntroTemplate = lazy(() => import("../../components/AllIntroTemplate/AllIntroTemplate"));
const AllAboutTemplate = lazy(() => import("../../components/AllAboutTemplate/AllAboutTemplate"));
const AllValues = lazy(() => import("../../components/AllValues/AllValues"));
// const SustainabilityServiceCatelogs = lazy(() => import("../../components/SustainabilityServiceCatelogs/SustainabilityServiceCatelogs"));

function Sustainability() {
  const companyData = TotalData[0];

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Sustainability - Priya Infra",
    "url": "http://localhost:3000/sustainability",
    "description": "Our commitment to sustainability is at the core of our operations, ensuring that every project we undertake contributes positively to the environment.",
    "publisher": {
      "@type": "Organization",
      "name": "Priya Infra",
      "url": "https://innotechconsultant.co.in/sustainability"
    },
  };

  return (
    <div className="about-container">
      <Suspense fallback={<div>Loading...</div>}>
        <Helmet>
          <title>Sustainability - Priya Infra</title>
          <meta name="description" content="Our commitment to sustainability is at the core of our operations, ensuring that every project we undertake contributes positively to the environment." />
          <meta property="og:title" content="Sustainability - Priya Infra" />
          <meta property="og:description" content="Our commitment to sustainability is at the core of our operations, ensuring that every project we undertake contributes positively to the environment." />
          <meta property="og:image" content={companyData.SustainabilityimageIntro} />
          <meta property="og:url" content="https://innotechconsultant.co.in/sustainability" />
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <AllIntroTemplate 
          description={companyData.SustainabilityDescription} 
          image={companyData.SustainabilityimageIntro} 
          title={companyData.SustainabilityTitle}
        />
        <AllAboutTemplate 
          description={companyData.SustainabilityAboutPara} 
          image={companyData.SustainabilityAboutimage1} 
          title={companyData.SustainabilityAbouttitle} 
          image2={companyData.SustainabilityAboutimage2}
        />
        {/* <SustainabilityServiceCatelogs data={companyData.Sustainable} /> */}
        <AllValues />
      </Suspense>
    </div>
  );
}

export default Sustainability;
