import servicesData from "../../JsonFiles/Services.json"
import projectsData from "../../JsonFiles/Projects.json"



export const links = [
    {
      name: "Area of Activities",
      submenu: true,
      link: "/services",
      sublinks: servicesData.map((item) => ({
        title: item.title,
        id: item.id,
      })),
    },
    {
      name: "Projects",
      submenu: true,
      link: "/projects",
      sublinks: projectsData.map((item) => ({
        title: item.title,
        id: item.id,
      })),
    },
  ];