import React, { useState, useEffect } from 'react';
import './AllProjects.css'; // Import CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const AllProjects = ({ cards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Slice the first 8 cards
  const visibleCards = cards.slice(0, 8);



  const goToPrevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? visibleCards.length - 1 : prevIndex - 1));
  };

  const goToNextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex === visibleCards.length - 1 ? 0 : prevIndex + 1));
  };

  // Define animations
  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  return (
    <div className='cardSlider-container'>
      <motion.div
        className="card-slider"
        ref={ref}
        initial={{ opacity: 0, y: 60 }}
        animate={animationControls}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <div className="card-container">
          <div
            key={currentIndex}
            className="card-about active"
          >
            <div className="left-section">
              <img
                src={visibleCards[currentIndex].image}
                alt={visibleCards[currentIndex].title}
                className='img-slider'
                loading="lazy"
              />
            </div>
            <div className="right-section">
              <h1 className='heading-RightCard'>Discover Our Projects_</h1>
              <div className="card-content">
                <Link to={`/projects/${visibleCards[currentIndex].id}`}>
                  <h2>{visibleCards[currentIndex].title}</h2>
                </Link>
                <p>{visibleCards[currentIndex].location}</p>
                <p>{visibleCards[currentIndex].description}</p>
              </div>
              <div className='sliderSection'>
                <FontAwesomeIcon icon={faChevronLeft} className="slider-button left" onClick={goToPrevCard} />
                <div className='index-total'>
                  {currentIndex + 1}/{visibleCards.length}
                </div>
                <FontAwesomeIcon icon={faChevronRight} className="slider-button right" onClick={goToNextCard} />
              </div>
              <Link to="/projects" className="Link-Projects">Find More of Our Case Studies<FontAwesomeIcon icon={faArrowRight} className="HomeIntro-iconRedirect" /></Link>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );

};

export default AllProjects;
