import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Footer.css';
import servicesData from '../../JsonFiles/Services.json';
import ProjectData from "../../JsonFiles/Projects.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram, faQuestion, faBuilding } from '@fortawesome/free-solid-svg-icons';
import TotalData from "../../JsonFiles/TotalData.json";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

// Custom Link component to handle scroll behavior
const Link = ({ to, children, ...rest }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <RouterLink to={to} onClick={handleClick} {...rest}>
      {children}
    </RouterLink>
  );
};

const Footer = () => {
  // Define animations
  const animationControls = useAnimation();
  const [ref, inView] = useInView();

  // Animate when the element comes into view
  useEffect(() => {
    if (inView) {
      animationControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [animationControls, inView]);

  return (
    <motion.div
      className="footer"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={animationControls}
    >
      {inView && (
  <button className="scrollToTopButton" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
    <FontAwesomeIcon icon={faArrowUp} />
  </button>
)}
      {TotalData.map((totaldata, index) => (
        <div className='HeadingBefore' key={`header-${index}`}>
          <h1 className='Footer-headings'>{totaldata.FooterDescriptionHeader}</h1>
          <p className='Footer-Description'>{totaldata.FooterDescriptionPara}</p>
        </div>
      ))}
      <hr></hr>
      <div className='gap-footer'></div>
      <div className="footer-container">
        <div className="footer-column">
          <FontAwesomeIcon icon={faBuilding} className='iconFooter' />
          <h2><Link to="/projects">Projects</Link></h2>
          <ul>
            {ProjectData.slice(0, 6).map(project => (
              <li key={project.id}><Link to={`/projects/${project.id}`}>{project.title}</Link></li>
            ))}
          </ul>
        </div>

        <div className="footer-column">
  <FontAwesomeIcon icon={faProjectDiagram} className='iconFooter' />
  <h2><Link to="/services">Area of Activities</Link></h2> {/* Change heading tag from <h2> to <h2> */}
  <ul>
    {servicesData.slice(0, 10).map(service => (
      <li key={service.id}><Link to={`/services/${service.id}`}>{service.title}</Link></li>
    ))}
  </ul>
</div>


        <div className="footer-column">
          <FontAwesomeIcon icon={faQuestion} className='iconFooter' />
          <h2><Link to="/company">About Us</Link></h2>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/why-priya">Why Priya Infra?</Link></li>
            <li><Link to="/company">Company</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/career">Career</Link></li>
          </ul>
        </div>

        <div className='location-cards-container'>
        {TotalData.map((totaldata, index) => (
  <div className="location-card" key={`location-${index}`}>
    <img src={totaldata.ImagesLogo} alt="Priya Infra Engineers Pvt Ltd - High-Quality Construction Services" className="footer-logo" />
    <h2><a href={totaldata.CompanyLink} target="_blank" rel="noopener noreferrer">{totaldata.CompanyName}</a></h2>
    
    <div>
    <p>Email: <a href={`mailto:${totaldata.Email}`}>{totaldata.Email}</a> </p>
      <p>Head Office: <a href={totaldata.Location1} target="_blank" rel="noopener noreferrer">{totaldata.Address1}</a></p>
      <p><a href={totaldata.Location2} target="_blank" rel="noopener noreferrer">{totaldata.Address2}</a></p>
      <p><a href={totaldata.Location3} target="_blank" rel="noopener noreferrer">{totaldata.Address3}</a></p>
    </div>
  </div>
))}
        </div>

        

        
      </div>

      {TotalData.map((totaldata, index) => (
        <div className="footer-bottom" key={`footer-bottom-${index}`}>
          <p className='copyRight'>&copy; {new Date().getFullYear()} All rights reserved by {totaldata.CompanyName}. Website Designed & Maintained by <a href="https://innotechconsultant.co.in/" className='link-innotech'>Innotech Consultant</a>.</p>
        </div>
      ))}
    </motion.div>
  );
};

export default Footer;
