import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

import ProjectCardRow from '../../components/ProjectCardRow/ProjectCardRow';
import totalDataJson from "../../JsonFiles/TotalData.json";
import projectsJson from "../../JsonFiles/Projects.json";

// Lazy load components
const AllIntroTemplateLazy = lazy(() => import('../../components/AllIntroTemplate/AllIntroTemplate'));

function Projects() {
  const [totalData, setTotalData] = useState(null);
  const [projects, setProjects] = useState(null);

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "name": "Projects - Priya Infra",
    "url": "https://www.priyainfra.com/projects",
    "description": "Explore our diverse portfolio of projects that showcase our expertise and commitment to excellence.",
    "publisher": {
      "@type": "Organization",
      "name": "Priya Infra",
      "url": "https://www.priyainfra.com"
    }
  };

  useEffect(() => {
    // Simulating data fetching
    setTotalData(totalDataJson);
    setProjects(projectsJson);
  }, []);

  if (!totalData || !projects) {
    return <div>Loading...</div>; // Render a loading state while fetching data
  }

  return (
    <div className="Projects-container">
      <Helmet>
        <title>Projects - Priya Infra</title>
        <meta name="description" content="Explore our diverse portfolio of projects that showcase our expertise and commitment to excellence." />
        <meta property="og:title" content="Projects - Priya Infra" />
        <meta property="og:description" content="Explore our diverse portfolio of projects that showcase our expertise and commitment to excellence." />
        <meta property="og:url" content="https://www.priyainfra.com/projects" />
        <meta property="og:image" content="URL_TO_PROJECTS_IMAGE" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.priyainfra.com/projects" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <AllIntroTemplateLazy
          description={totalData[0].ProjectsDescription}
          image={totalData[0].Projectsimage}
          title={totalData[0].Projectstitle}
        />
        <ProjectCardRow data={projects} />
      </Suspense>
    </div>
  );
}

export default Projects;
