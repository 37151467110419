import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import './Contact.css'; // Import the CSS file
import TotalData from "../../JsonFiles/TotalData.json";

const AllIntroTemplate = lazy(() => import('../../components/AllIntroTemplate/AllIntroTemplate'));
const CareersJobs = lazy(() => import('../../components/CareersJobs/CareersJobs'));
const ContactMap = lazy(() => import('../../components/ContactMap/ContactMap'));

function Contact() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "ContactPage",
    "name": "Contact - Priya Infra",
    "url": "http://localhost:3000/contact",
    "description": "Get in touch with Priya Infra. Find our contact details, location, and send us your queries.",
    "publisher": {
      "@type": "Organization",
      "name": "Priya Infra",
      "url": "https://innotechconsultant.co.in/"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+1-800-555-1234",
      "contactType": "Customer Service"
    }
  };

  return (
    <div className="Contact-container">
      <Helmet>
        <title>Contact - Priya Infra</title>
        <meta name="description" content="Get in touch with Priya Infra. Find our contact details, location, and send us your queries." />
        <meta property="og:title" content="Contact - Priya Infra" />
        <meta property="og:description" content="Get in touch with Priya Infra. Find our contact details, location, and send us your queries." />
        <meta property="og:image" content="/images/contact.webp" />
        <meta property="og:url" content="https://innotechconsultant.co.in/contact" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
      <AllIntroTemplate description={TotalData[0].ContactusDescription} image={TotalData[0].Contactusimage} title={TotalData[0].Contactustitle}/>
        <ContactMap data={TotalData[0].ContactMap}/>
        <CareersJobs/>
      </Suspense>
    </div>
  );
}

export default Contact;
